import axios from "axios";

const apiLink = "https://autostar.sigma-studio.pp.ua/";
export async function getCarLink(form) {
	try {
		const response = await axios.post(apiLink + "getCarLink", form);
		const data = response.data;
		return data;
	} catch (errors) {
		console.error(errors);
	}
}

export async function getCarOwner(form) {
	try {
		const response = await axios.post(apiLink + "getCarOwner", form);
		const data = response.data;
		return data;
	} catch (errors) {
		console.error(errors);
	}
}
