import axios from "axios";

const apiLink = "https://autostar.sigma-studio.pp.ua/";

export async function getStats() {
	try {
		const response = await axios.get(apiLink + "getStatistic");
		const data = response.data;
		return data;
	} catch (errors) {
		console.error(errors);
	}
}
