<template>
	<div class="input_container">
		<input
			:type="type"
			:placeholder="placeholder"
			v-model="editedInput"
			v-if="element === 'input'"
			@input="updateValue()"
			@blur="$emit('blur')"
		/>
		<textarea
			:type="type"
			:placeholder="placeholder"
			v-model="editedInput"
			v-if="element === 'textarea'"
			@input="updateValue()"
		></textarea>
		<label v-show="prompt">{{ label }}</label>
	</div>
</template>

<script>
export default {
	props: {
		element: {
			type: String,
			required: false,
			default: "input"
		},
		type: {
			type: String,
			required: false,
			default: "text"
		},
		placeholder: {
			type: String,
			required: false,
			default: "Введите"
		},
		label: {
			type: String,
			required: false,
			default: ""
		},
		input: {
			type: String,
			required: false,
			default: ""
		},
		prompt: {
			type: Boolean,
			required: false,
			default: true
		}
	},

	data() {
		return {
			editedInput: this.input
		};
	},

	methods: {
		updateValue() {
			this.$emit("update:value", this.editedInput);
		},

		setValue(value) {
			this.editedInput = value;
		}
	}
};
</script>

<style lang="scss" scoped>
.input_container {
	position: relative;
	border-bottom: 1px solid #353535;
	input,
	textarea {
		font-size: 0.938em;
		padding: 1.063em 0.313em;
		color: white;
		resize: none;
		&::placeholder {
			color: #9c9c9c;
			transition: $defaultTransition;
		}
		&:focus {
			&::placeholder {
				opacity: 0;
				transform: translateX(-10px);
			}
			~ label {
				opacity: 0.6;
				transform: translate(0, -50%);
			}
		}
	}
}
label {
	position: absolute;
	top: 50%;
	font-size: 0.75em;
	transform: translate(-10px, -50%);
	right: 1.3em;
	color: #9c9c9c;
	font-weight: 500;
	opacity: 0;
	transition: all 0.4s ease-in-out;
	pointer-events: none;
	transition: $defaultTransition;
}
</style>
