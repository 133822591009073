export function removeURLParameter(url, parameters) {
	let newUrl = "";
	for (let p = 0; p < parameters.length; p++) {
		var urlparts = url.split("?");
		if (urlparts.length >= 2) {
			var prefix = encodeURIComponent(parameters[p]) + "=";
			var pars = urlparts[1].split(/[&;]/g);

			for (var i = pars.length; i-- > 0; ) {
				if (pars[i].lastIndexOf(prefix, 0) !== -1) {
					pars.splice(i, 1);
				}
			}

			if (pars.length > 0) {
				url = urlparts[0] + "?" + pars.join("&");
			} else {
				url = urlparts[0];
			}
			newUrl = url;
		} else {
			newUrl = url;
		}
	}
	return newUrl.replace(
		window.location.protocol + "//" + window.location.host,
		""
	);
}
