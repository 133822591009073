<template>
	<div class="page_content main">
		<div class="logo_block">
			<div class="logo" @click="updateAnimations()">
				<img src="@/assets/svg/logo.svg" alt="" />
				<svg
					id="svg"
					viewBox="0 0 100 100"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle
						id="border"
						r="48"
						cx="50"
						cy="50"
						fill="transparent"
						stroke-dasharray="313.65"
						stroke-dashoffset="0"
					></circle>
					<circle
						id="bar"
						r="48"
						cx="50"
						cy="50"
						fill="transparent"
						stroke-dasharray="313.65"
						stroke-dashoffset="0"
					></circle>
				</svg>
			</div>
		</div>
		<row-filter @updateAnimations="updateAnimations()" />
		<div class="main_statistics container">
			<div class="statistics">
				<div class="top_line">
					<div class="left_block">
						<div class="check"></div>
						<span class="title">Звонков</span>
					</div>
					<div class="right_block">
						<div class="counts">
							<vue3-autocounter
								ref="callCounter"
								:startAmount="0"
								:endAmount="45"
								:duration="2"
								separator=" "
								decimalSeparator="."
								:decimals="0"
								:autoinit="true"
							/>
						</div>
					</div>
				</div>
				<div class="center_line">
					<div class="total">
						₴
						<vue3-autocounter
							ref="totalCounter"
							:startAmount="0"
							:endAmount="stats.total"
							:duration="3"
							separator=" "
							decimalSeparator="."
							:decimals="0"
							:autoinit="true"
						/>
					</div>
				</div>
				<div class="bottom_line">
					<div class="left_block">
						<div class="total">Машин: 7</div>
					</div>
					<div class="right_block">
						<div class="counts">
							<div class="count">58 Face</div>
							<div class="count red">
								<vue3-autocounter
									ref="faceCounter"
									:startAmount="0"
									:endAmount="2340"
									:duration="3"
									separator=" "
									decimalSeparator="."
									:decimals="0"
									:autoinit="true"
								/>
								St
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="payments">
				<div class="card block">
					<div class="title">Оплата картой</div>
					<div class="bottom">
						<div class="total">83 632</div>
						<div class="icon"></div>
					</div>
				</div>
				<div class="cash block">
					<div class="total">₴ 9 835</div>
					<div class="title">Наличными</div>
				</div>
			</div>
		</div>
		<div class="block_title categories_title">
			<div class="title">Статистика разделов</div>
			<div class="icon"></div>
		</div>
		<div class="container">
			<div class="categories">
				<div class="category">
					<div class="icon">
						<img src="@/assets/svg/categories/1.svg" alt="" />
					</div>
					<span class="category_title">Заходов</span>
					<div class="stats">
						<span class="title">Всего</span>
						<span class="delimieter">-</span>
						<span class="value">2 730</span>
					</div>
				</div>
				<div class="category">
					<div class="icon">
						<img src="@/assets/svg/categories/2.svg" alt="" />
					</div>
					<span class="category_title">Машин</span>
					<div class="stats">
						<span class="title">Авто</span>
						<span class="delimieter">-</span>
						<span class="value">{{ stats.carCount }}</span>
					</div>
				</div>
				<div class="category">
					<div class="icon">
						<img src="@/assets/svg/categories/3.svg" alt="" />
					</div>
					<span class="category_title">Лиды</span>
					<div class="stats">
						<span class="title">Человек</span>
						<span class="delimieter">-</span>
						<span class="value">34</span>
					</div>
				</div>
				<div class="category">
					<div class="icon">
						<img src="@/assets/svg/categories/4.svg" alt="" />
					</div>
					<span class="category_title">Звонков</span>
					<div class="stats">
						<span class="title">Всего</span>
						<span class="delimieter">-</span>
						<span class="value">128</span>
					</div>
				</div>
				<div class="category">
					<div class="icon">
						<img src="@/assets/svg/categories/5.svg" alt="" />
					</div>
					<span class="category_title">Заявок</span>
					<div class="stats">
						<span class="title">Формы</span>
						<span class="delimieter">-</span>
						<span class="value">57</span>
					</div>
				</div>
				<div class="category">
					<div class="icon">
						<img src="@/assets/svg/categories/6.svg" alt="" />
					</div>
					<span class="category_title">Оплат</span>
					<div class="stats">
						<span class="title green">{{ stats.saledCount }} шт</span>
						<span class="delimieter">-</span>
						<span class="value">{{ stats.comission }}</span>
					</div>
				</div>
				<div class="category">
					<div class="icon">
						<img src="@/assets/svg/categories/7.svg" alt="" />
					</div>
					<span class="category_title">Страховки</span>
					<div class="stats">
						<span class="title">Оплат</span>
						<span class="delimieter">-</span>
						<span class="value">5 720</span>
					</div>
				</div>
				<div class="category">
					<div class="icon">
						<img src="@/assets/svg/categories/8.svg" alt="" />
					</div>
					<span class="category_title">Отчеты</span>
					<div class="stats">
						<span class="title">Оплат</span>
						<span class="delimieter">-</span>
						<span class="value">19 670</span>
					</div>
				</div>
			</div>
		</div>
		<div class="users_container">
			<div class="block_title users_title" @click="createUser()">
				<div class="title">Все звонки</div>
				<div class="icon"></div>
			</div>
			<div class="container">
				<div class="users">
					<user-call
						:key="call.id"
						v-for="call in calls"
						:info="call"
						@openEditModal="openEditModal($event)"
					/>
				</div>
			</div>
			<user-modal
				ref="userModal"
				@addUser="addUser($event)"
				@deleteUser="deleteUser($event)"
				:lastIndex="calls.length ? parseInt(calls[calls.length - 1].id, 10) : 0"
			/>
		</div>
	</div>
</template>

<script>
import { getCalls } from "@/api/Calls";
import { getStats } from "@/api/Stats";
import Vue3autocounter from "vue3-autocounter";
import RowFilter from "@/components/Filter/RowFilter.vue";
import UserCall from "@/components/Blocks/UserCall.vue";
import UserModal from "@/components/Modals/UserModal.vue";
export default {
	components: {
		RowFilter,
		UserCall,
		UserModal,
		"vue3-autocounter": Vue3autocounter
	},

	data() {
		return {
			calls: [],
			stats: {}
		};
	},
	methods: {
		async getCalls() {
			let response = await getCalls();
			this.calls = response;
		},
		createUser() {
			this.$refs.userModal.add();
		},

		openEditModal(form) {
			this.$refs.userModal.edit(form);
		},

		addUser(data) {
			this.calls.push(data);
		},

		deleteUser(id) {
			let index = this.calls.findIndex(
				(item) => parseInt(item.id, 10) === parseInt(id, 10)
			);
			this.calls.splice(index, 1);
		},

		updateAnimations() {
			this.$refs.callCounter.reset();
			this.$refs.totalCounter.reset();
			this.$refs.faceCounter.reset();
			document.querySelector(".logo").classList.remove("anim");
			setTimeout(() => {
				this.$refs.callCounter.start();
				this.$refs.totalCounter.start();
				this.$refs.faceCounter.start();
			}, 100);
			setTimeout(() => {
				document.querySelector(".logo").classList.add("anim");
			}, 950);
		}
	},

	async created() {
		await this.getCalls();
		this.stats = await getStats();
	},
	// mounted() {
	// 	setTimeout(() => {
	// 		//this.$refs.counter.start();
	// 	}, 200);
	// }
	mounted() {
		setTimeout(() => {
			document.querySelector(".logo").classList.add("anim");
		}, 500);
	}
};
</script>

<style lang="scss" scoped>
.logo_block {
	padding: 2.5em 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.logo {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 9.2em;
	height: 9.2em;
	border-radius: 50%;
	line-height: 0;
	&.anim {
		#bar {
			stroke-dashoffset: 100px;
		}
	}
	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 110%;
		height: 110%;
		circle {
			stroke-width: 2px;
		}
		#border {
			stroke: #333333;
		}
		#bar {
			stroke: $red;
			stroke-dashoffset: 314px;
			transform: rotate(-90deg);
			transform-origin: center;
			transition: all 1s ease-in-out;
		}
	}
	img {
		width: 80%;
		object-fit: contain;
	}
}
.main_statistics {
	margin-top: 1.7em;
	.statistics {
		padding: 1.2em 1.4em;
		border-radius: 16px;
		background-color: $bgColor;
		.check {
			width: 2.5em;
			height: 2.5em;
			border-radius: 50%;
			background-color: $red;
			background-repeat: no-repeat;
			background-position: 50%;
			background-size: 75%;
			background-image: url("~@/assets/svg/check.svg");
		}
		.top_line {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.left_block {
				display: flex;
				align-items: center;
				.title {
					font-size: 0.913em;
					margin-left: 0.9em;
				}
			}
			.counts {
				font-weight: 600;
			}
		}
		.center_line {
			margin-top: 0.6em;
			.total {
				font-size: 1.913em;
				font-weight: 600;
			}
		}
		.bottom_line {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 0.3em;
			font-size: 0.913em;
			.right_block {
				.counts {
					display: flex;
					margin: 0 -0.4em;
					.count {
						display: flex;
						justify-content: space-between;
						padding: 0.3em 1.2em;
						margin: 0 0.4em;
						font-size: 0.75em;
						font-weight: 600;
						background-color: #414141;
						border-radius: 50px;
						&.red {
							background-color: $red;
						}
						span {
							display: block;
							width: 3.3em;
							text-align: center;
						}
					}
				}
			}
		}
	}
}
.payments {
	display: flex;
	justify-content: space-between;
	margin-top: 2.225em;
	padding: 0 0.225em;
	.block {
		width: 46.5%;
		border-radius: 16px;
	}
	.card {
		padding: 0.5em 1em 0.5em 0.9em;
		background-color: $bgColor;
		.title {
			font-size: 0.75em;
		}
		.bottom {
			display: flex;
			justify-content: space-between;
			margin-top: 1.4em;
			.total {
				font-weight: 600;
			}
			.icon {
				width: 1.7em;
				height: 1.2em;
				background-repeat: no-repeat;
				background-position: 50%;
				background-size: contain;
				background-image: url("~@/assets/svg/card.svg");
			}
		}
	}
	.cash {
		padding: 1em 0.7em 0.7em;
		.total {
			font-size: 1.913em;
			font-weight: 600;
			line-height: 1;
			letter-spacing: -0.04em;
		}
		.title {
			font-size: 0.813em;
			margin-top: 0.5em;
		}
	}
}
.block_title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 1.6em 0;
	padding: 0 1.7em;
	.title {
		font-size: 0.938em;
		color: #828282;
		font-weight: 600;
	}
}
.categories {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.category {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 1.1em 0;
		margin-bottom: 1.2em;
		width: 46.5%;
		border-radius: 11px;
		background-color: $bgColor;
		.icon {
			width: 3.313em;
			height: 3.313em;
			img {
				object-fit: contain;
			}
		}
		.category_title {
			margin-top: 0.6em;
			color: #9c9c9c;
			font-size: 1.063em;
		}
		.stats {
			margin-top: 0.313em;
			font-size: 0.875em;
			.title {
				color: $red;
				&.green {
					color: $green;
				}
			}
			.delimieter {
				padding: 0 0.2em;
			}
		}
	}
}
.users_container {
	position: relative;
	.icon {
		position: relative;
		width: 1.5em;
		height: 1.5em;
		border: 1px solid #b8b8b8;
		border-radius: 50%;
		&:after,
		&:before {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: #b8b8b8;
		}
		&:after {
			width: 60%;
			height: 2px;
		}
		&:before {
			height: 60%;
			width: 2px;
		}
	}
}
.users_title {
	margin: 1.1em 0 0.75em;
}
</style>
