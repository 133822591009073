<template>
	<header class="header">
		<nav class="navigation">
			<ul>
				<li>
					<router-link to="/">Продажи</router-link>
				</li>
				<li>
					<a
						href="https://autostar.sigma-studio.pp.ua/admincp.php/?pages=Archer&type=auto"
						>Машины</a
					>
				</li>
			</ul>
		</nav>
	</header>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.navigation {
	background-color: $bgColor;
}
ul {
	display: flex;
	justify-content: space-between;
}
li {
	width: 50%;
}
a {
	position: relative;
	display: block;
	padding: 0.688em 0;
	font-size: 0.875em;
	text-transform: uppercase;
	font-weight: 500;
	text-align: center;
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 0;
		height: 4px;
		transform: translateX(-50%);
		background-color: $red;
	}
	&.router-link-exact-active {
		&:after {
			width: 100%;
		}
	}
}
</style>
