import axios from "axios";

const apiLink = "https://autostar.sigma-studio.pp.ua/";
export async function getCalls() {
	try {
		const response = await axios.get(apiLink + "getCalls");
		const data = response.data;
		return data;
	} catch (errors) {
		console.error(errors);
	}
}

export async function addUser(form) {
	try {
		const response = await axios.post(apiLink + "addUser", form);
		const data = response.data;
		return data;
	} catch (errors) {
		console.error(errors);
	}
}

export async function editUser(form) {
	try {
		const response = await axios.post(apiLink + "editUser", form);
		const data = response.data;
		return data;
	} catch (errors) {
		console.error(errors);
	}
}

export async function deleteUser(form) {
	try {
		const response = await axios.post(apiLink + "deleteUser", form);
		const data = response.data;
		return data;
	} catch (errors) {
		console.error(errors);
	}
}
