<template>
	<div class="row_filter container">
		<ul class="variable_list">
			<li
				class="variable"
				:class="{ active: variable === selectedFilter }"
				:key="variable"
				v-for="variable in variables"
				@click="selectFilter(variable)"
			>
				<span>{{ variable }}</span>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	data() {
		return {
			variables: ["День", "Вчера", "Неделя", "Месяц"],
			selectedFilter: "День"
		};
	},

	methods: {
		selectFilter(filter) {
			this.selectedFilter = filter;
			this.$emit("updateAnimations");
		}
	}
};
</script>

<style lang="scss" scoped>
.variable_list {
	display: flex;
	justify-content: space-between;
	margin-top: -0.3em;
}
.variable {
	padding: 0.4em 0.9em;
	border-radius: 50px;
	transition: $defaultTransition;
	&.active {
		background-color: $bgColor;
		span {
			color: white;
		}
	}
	span {
		display: block;
		color: #646363;
		font-size: 0.875em;
		font-weight: 500;
		line-height: 1.2;
		transition: $defaultTransition;
	}
}
</style>
