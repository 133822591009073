<template>
	<custom-header />
	<router-view ref="page" />
	<bottom-bar />
	<!-- <Footer /> -->
</template>

<script>
import CustomHeader from "@/components/Common/CustomHeader";
import BottomBar from "@/components/Navigation/BottomBar";
// import Footer from "@/components/Common/Footer";

export default {
	components: {
		CustomHeader,
		BottomBar
		//Footer
	}
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
</style>
