<template>
	<label class="variable">
		<input
			type="radio"
			:name="name"
			:value="value"
			v-model="editedInput"
			@change="updateValue($event.target.value)"
		/>
		<div class="checkmark"></div>
	</label>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		currentValue: {
			type: String,
			required: false
		}
	},

	data() {
		return {
			editedInput: this.currentValue
		};
	},

	methods: {
		updateValue(value) {
			this.$emit("update:value", value);
		}
	}
};
</script>

<style lang="scss" scoped>
.variable {
	position: relative;
}
input {
	position: absolute;
	visibility: hidden;
	width: 0;
	height: 0;
	&:checked {
		+ .checkmark {
			&:after {
				opacity: 1;
			}
			&:before {
				opacity: 0.7;
			}
		}
	}
}
.checkmark {
	width: 1.5em;
	height: 1.5em;
	border-radius: 50%;
	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: 75%;
		background-image: url("~@/assets/svg/check.svg");
		opacity: 0;
		transition: $defaultTransition;
	}
	&:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 120%;
		height: 120%;
		transform: translate(-50%, -50%);
		border: 3px solid;
		border-radius: 50%;
		opacity: 0;
		transition: $defaultTransition;
	}
}
</style>
