<template>
	<!-- <div
		class="user"
		@touchstart="clickHandler()"
		@touchend="clearHold()"
		ref="call"
	> -->
	<div class="user" @click="openEditModal" ref="call" :class="info.status">
		<div class="icon" :class="{ play: played }"></div>
		<div class="info">
			<div class="top_block">
				<div class="main_info">
					<span class="name">{{ info.name }}</span>
					<!-- <span class="name" v-else>Звонок</span> -->
					<span class="car" v-if="info.car">{{ info.car }}</span>
					<span class="car" v-else>Нет данных</span>
				</div>
				<span class="price" v-if="info.car_price">{{ formatNumber }} $</span>
				<span class="price no_price" v-else>+ Добавить</span>
			</div>
			<div class="bottom_block">
				<a class="phone" v-if="info.telephone">{{ info.telephone }}</a>
				<a class="phone" v-else>Телефон</a>
				<div class="date_block">
					<span class="date">{{ info.date }}</span>
					<span class="time">{{ info.time }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		info: {
			type: Object,
			required: true
		}
	},

	emits: {
		openEditModal: null
	},

	data() {
		return {
			played: false,
			audio: null,
			timer: null
		};
	},

	computed: {
		formatNumber() {
			return new Intl.NumberFormat("ru-RU").format(this.info.car_price);
		}
	},

	methods: {
		clickHandler() {
			this.$refs.call.classList.add("active");
			this.clearHold();
			this.timer = setTimeout(() => {
				this.openEditModal();
			}, 700);
		},
		clearHold() {
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
				this.$refs.call.classList.remove("active");
			}
		},

		openEditModal() {
			this.$emit("openEditModal", this.info);
			// setTimeout(() => {
			// 	this.clearHold();
			// }, 400);
		},

		play() {
			if (this.info.call_record && this.info.call_record.status === "success") {
				if (!this.audio) {
					this.audio = new Audio();
					this.audio.src = this.info.call_record.url;
				}
				this.audio.play();
				this.played = true;
			} else {
				alert("Нет записи разговора");
			}
		},

		pause() {
			this.audio.pause();
			this.played = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.user {
	position: relative;
	display: flex;
	justify-content: space-between;
	padding: 0.75em 0.813em;
	margin-bottom: 0.875em;
	background-color: $bgColor;
	letter-spacing: 0.03em;
	border-radius: 11px;
	&.active {
		&:after {
			height: 100%;
		}
	}
	&.success {
		.icon {
			background-color: $green;
			&:after {
				background-image: url("~@/assets/svg/check.svg");
			}
		}
	}
	&.working {
		.icon {
			background-color: #414141;
			&:after {
				background-position: 65% 50%;
				background-image: url("~@/assets/svg/call.svg");
			}
		}
	}
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 0;
		background-color: #161616;
		transition: all 700ms linear;
		z-index: 0;
	}
	.icon {
		position: relative;
		width: 2.5em;
		height: 2.5em;
		margin-top: 0.188em;
		border-radius: 50%;
		background-color: $red;
		&:after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 1.5em;
			height: 1.5em;
			background-repeat: no-repeat;
			background-position: 50%;
			background-size: contain;
			background-image: url("~@/assets/svg/profile.svg");
			transition: $defaultTransition;
		}
		&.play {
			background-color: white;
			background-size: 33%;
			background-image: url("~@/assets/svg/play.svg");
		}
	}
	.info {
		width: 83%;
	}
	.top_block {
		display: flex;
		justify-content: space-between;
		padding-bottom: 0.313em;
		border-bottom: 1px solid #3e3e3e;
	}
	.name {
		display: block;
		font-size: 0.938em;
		line-height: 1.45;
	}
	.car {
		display: block;
		font-size: 0.688em;
		color: #979797;
		letter-spacing: 0.03em;
	}
	.price {
		font-size: 0.913em;
		color: $red;
		&.no_price {
			color: $green;
		}
	}
	.phone {
	}
	.bottom_block {
		display: flex;
		justify-content: space-between;
		margin-top: 0.375em;
		font-size: 0.813em;
	}
	.date_block {
		display: flex;
		color: #979797;
		.time {
			padding-left: 1em;
		}
	}
}
</style>
