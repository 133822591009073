<template>
	<transition name="show">
		<div class="user_modal" v-if="show">
			<div class="buttons">
				<div class="button cancel" @click="close()">Отменить</div>
				<div class="button delete" @click="deleteUser()">Удалить</div>
				<div
					class="button save"
					@click="event === 'add' ? addUser() : editUser()"
				>
					Сохранить
				</div>
			</div>
			<div class="form">
				<div class="form_container main_data">
					<div class="status_list">
						<custom-radio
							class="status working"
							value="working"
							:currentValue="editableForm.status"
							v-model:value="editableForm.status"
							name="status"
						/>
						<custom-radio
							class="status reserve"
							value="reserve"
							:currentValue="editableForm.status"
							v-model:value="editableForm.status"
							name="status"
						/>
						<custom-radio
							class="status success"
							value="success"
							:currentValue="editableForm.status"
							v-model:value="editableForm.status"
							name="status"
							@click="showSuccessSnackBar()"
						/>
					</div>
					<custom-input
						placeholder="ФИО"
						type="text"
						label="ФИО"
						:input="editableForm.name"
						v-model:value="editableForm.name"
						:prompt="false"
					/>
					<custom-input
						placeholder="Город"
						type="text"
						label="Город"
						:input="editableForm.city"
						v-model:value="editableForm.city"
					/>
					<!-- <custom-input
						placeholder="ID машины"
						type="text"
						label="ID машины"
						:input="editableForm.car"
						v-model:value="editableForm.car"
						@blur="getCarLink()"
					/> -->
					<custom-input
						element="textarea"
						placeholder="Комментарий"
						label="Комментарий"
						:input="editableForm.description"
						v-model:value="editableForm.description"
					/>
				</div>
				<!-- <div class="form_container dealer">
					<custom-select placeholder="Площадка" :list="dealers" />
				</div>
				<div class="form_container manager">
					<custom-input placeholder="Менеджер" type="text" label="Менеджер" />
				</div> -->
				<div class="form_container link">
					<div class="link_icon"></div>
					<custom-input
						placeholder="Ссылка на авто"
						type="text"
						label="Ссылка"
						ref="inputCarLink"
						:input="editableForm.carLink"
						v-model:value="editableForm.carLink"
						@click="pasteFromClipboard('carLink')"
						:prompt="false"
					/>
				</div>
				<div class="form_container">
					<custom-input
						placeholder="Телефон"
						type="number"
						label="Телефон"
						:input="editableForm.telephone"
						v-model:value="editableForm.telephone"
					/>
				</div>
				<div
					class="form_container transfer"
					:class="{ success: canTransfer }"
					@click="transferClient"
				>
					<div class="transfer_icon"></div>
					<custom-input placeholder="Передать клиента" type="text" />
				</div>
				<div
					class="form_container order"
					:class="{ success: canPay }"
					@click="bill"
				>
					<div class="order_icon"></div>
					<custom-input placeholder="Выставить счет" type="text" label="Счет" />
				</div>
			</div>
			<transition name="show">
				<div class="success_snack" v-show="showSnackBar">
					<div class="icon"></div>
					<span class="title">Комиссия от продажи </span>
					<span class="title cost"
						><vue3-autocounter
							ref="counter"
							:startAmount="0"
							:endAmount="comission"
							:duration="2"
							separator=","
							decimalSeparator="."
							:decimals="0"
							:autoinit="true"
						/>$</span
					>
				</div>
			</transition>
		</div>
	</transition>
</template>

<script>
import { addUser, deleteUser, editUser } from "@/api/Calls";
import Vue3autocounter from "vue3-autocounter";
import { getCarOwner } from "@/api/Cars";
import CustomInput from "@/components/Inputs/CustomInput.vue";
import { copyTextToClipboard } from "@/plugins/copy.js";
import { removeURLParameter } from "@/plugins/UrlCleaner.js";
import { disableScroll, enableScroll } from "@/plugins/scrollLock.js";
import device from "current-device";
//import CustomSelect from "@/components/Inputs/CustomSelect.vue";
import CustomRadio from "@/components/Inputs/CustomRadio.vue";
export default {
	components: {
		CustomInput,
		//CustomSelect,
		CustomRadio,
		"vue3-autocounter": Vue3autocounter
	},

	props: {
		lastIndex: {
			type: Number,
			required: true
		}
	},

	data() {
		return {
			show: false,
			editableForm: {
				status: "working",
				name: "",
				city: "",
				carLink: "",
				telephone: "",
				owner_telephone: "",
				description: "",
				viberLink: ""
			},
			comission: 0,
			showSnackBar: false,
			event: "add",
			audio: null,
			privatLink:
				"https://www.privat24.ua/rd/transfer_to_card?hash=rd/transfer_to_card/",
			paymentData: {
				from: "",
				to: "4246 0010 0063 9736",
				ccy: "UAH",
				amt: "0"
			}
		};
	},

	computed: {
		canTransfer() {
			return (
				this.editableForm.name.length &&
				this.editableForm.city.length &&
				this.editableForm.carLink.length &&
				this.editableForm.telephone.length
			);
		},

		canPay() {
			return this.editableForm.status === "reserve";
		}
	},

	methods: {
		open() {
			this.show = true;
			setTimeout(() => {
				disableScroll(device.ios());
			}, 300);
		},

		edit(form) {
			this.open();
			this.event = "edit";
			this.editableForm = form;
			this.getCarOwner();
		},

		add() {
			this.open();
			this.event = "add";
		},

		close() {
			this.show = false;
			this.clearForm();
			enableScroll(document.querySelector(".search_history"));
		},

		async deleteUser() {
			let formData = new FormData();
			formData.append("id", this.editableForm.id);
			await deleteUser(formData);
			this.$emit("deleteUser", this.editableForm.id);
			this.clearForm();
			this.close();
		},

		async addUser() {
			let formData = new FormData();
			for (let key in this.editableForm) {
				formData.append(key, this.editableForm[key]);
			}
			await addUser(formData);
			const currentDate = new Date();
			const hours = ("0" + currentDate.getHours()).slice(-2);
			const minutes = ("0" + currentDate.getMinutes()).slice(-2);
			const day = ("0" + currentDate.getDate()).slice(-2);
			const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
			this.editableForm.date = day + "." + month;
			this.editableForm.time = hours + ":" + minutes;
			this.editableForm.id = this.lastIndex + 1;
			this.$emit("addUser", this.editableForm);
			this.clearForm();
			//this.editableForm.type = "user";
			this.close();
		},

		async editUser() {
			let formData = new FormData();
			for (let key in this.editableForm) {
				formData.append(key, this.editableForm[key]);
			}
			await editUser(formData);
			this.$emit("editUser", this.editableForm);
			this.clearForm();
			this.close();
		},

		clearForm() {
			this.editableForm = {
				status: "working",
				name: "",
				city: "",
				carLink: "",
				telephone: "",
				owner_telephone: "",
				description: "",
				viberLink: ""
			};
		},

		// async getCarLink() {
		// 	let formData = new FormData();
		// 	formData.append("id", this.editableForm.car);
		// 	const result = await getCarLink(formData);
		// 	if (result.status === "success") {
		// 		this.editableForm.car_link = result.link;
		// 	}
		// },

		async getCarOwner() {
			// if (!this.editableForm.carLink) {
			// 	alert("Вставьте ссылку");
			// }
			this.comission = 0;
			let formData = new FormData();
			formData.append("link", this.editableForm.carLink);
			const response = await getCarOwner(formData);
			if (response.status === "success") {
				this.editableForm.owner_telephone = response.telephone;
				this.editableForm.viberLink = response.viber_link;
				if (response.car_price) {
					this.paymentData.amt = parseInt(response.car_price) * (1 / 100);
					this.comission = this.paymentData.amt;
				}
				this.editableForm.car_price = response.car_price;
				this.editableForm.car = response.car;
			}
		},

		async pasteFromClipboard(name) {
			let text = await navigator.clipboard.readText();
			//if (text && !this.editableForm[name]) {
			if (text) {
				text = removeURLParameter(text, ["shared", "preview"]);
				this.editableForm[name] = text;
				this.$refs.inputCarLink.setValue(text);
				await this.getCarOwner();
			}
		},

		transferClient() {
			const clipboardText = `КЛИЕНТ\r\nИмя: ${this.editableForm.name}\r\nГород: ${this.editableForm.city}\r\nТелефон: ${this.editableForm.telephone}\r\nСсылка: ${this.editableForm.carLink}\r\n`;
			copyTextToClipboard(clipboardText);
			window.location.href = this.editableForm.viberLink;
		},

		showSuccessSnackBar() {
			this.showSnackBar = true;
			this.audio.play();
			setTimeout(() => {
				this.$refs.counter.start();
			}, 500);
			setTimeout(() => {
				this.showSnackBar = false;
				//this.$refs.counter.reset();
			}, 4000);
		},

		bill() {
			const clipboardText = encodeURI(
				this.privatLink + JSON.stringify(this.paymentData)
			);
			copyTextToClipboard(clipboardText);
			window.location.href = this.editableForm.viberLink;
		}
	},

	mounted() {
		this.audio = new Audio();
		this.audio.src = "./cash.mp3";
	}
};
</script>

<style lang="scss" scoped>
.user_modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	padding: 1em 1.2em 6em;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	background-color: #161616;
	transition: all 300ms ease;
	&.show-enter-active,
	&.show-leave-active {
		opacity: 0;
	}
}
.buttons {
	display: flex;
	justify-content: space-between;
	.button {
		font-size: 0.938em;
		&.cancel {
			color: #9c9c9c;
		}
		&.delete {
			color: $red;
		}
		&.save {
			color: $green;
		}
	}
}
.form {
	margin-top: 1.2em;
	.status_list {
		position: absolute;
		top: 1em;
		right: 1em;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 -0.375em;
		z-index: 9;
		.status {
			position: relative;
			display: block;
			margin: 0 0.375em;
			&.working {
				:deep() {
					.checkmark {
						background-color: #505050;
						&:before {
							border-color: #505050;
						}
					}
				}
			}
			&.reserve {
				:deep() {
					.checkmark {
						background-color: $red;
						&:before {
							border-color: $red;
						}
					}
				}
			}
			&.success {
				:deep() {
					.checkmark {
						background-color: $green;
						&:before {
							border-color: $green;
						}
					}
				}
			}
		}
	}
	.form_container {
		position: relative;
		padding: 0em 1em;
		background-color: $bg2Color;
		border-radius: 9px;
		margin-bottom: 0.938em;
		&.main_data {
			padding: 0 1em 0.7em;
		}
		&.link {
			:deep() {
				input {
					padding-right: 1.9em;
				}
			}
		}
		&.success {
			.input_container {
				:deep() {
					input {
						&::placeholder {
							color: white;
						}
					}
				}
			}
		}
	}
	.input_container {
		&:last-child {
			border: none;
		}
	}
	.link_icon {
		position: absolute;
		top: 50%;
		right: 0.5em;
		width: 1.813em;
		height: 1.813em;
		background-color: $green;
		transform: translateY(-50%);
		border-radius: 50%;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: 70%;
		background-image: url("@/assets/svg/copy.svg");
		z-index: 9;
	}
	.transfer_icon {
		position: absolute;
		top: 50%;
		right: 0.5em;
		width: 1.125em;
		height: 1.125em;
		transform: translateY(-50%);
		border-radius: 50%;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: 70%;
		background-image: url("@/assets/svg/arrow-right.svg");
		z-index: 9;
	}
	.order_icon {
		position: absolute;
		top: 50%;
		right: 0.5em;
		width: 1.813em;
		height: 1.813em;
		background-color: #054995;
		transform: translateY(-50%);
		border-radius: 50%;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: 60%;
		background-image: url("@/assets/svg/pay.svg");
		z-index: 9;
	}
}
.success_snack {
	position: fixed;
	bottom: 6.8em;
	left: 50%;
	width: calc(100% - 2.4em);
	display: flex;
	align-items: center;
	border-radius: 12px;
	padding: 0.6em 0.8em;
	transform: translate(-50%, 0);
	background-color: #5d9815;
	white-space: nowrap;
	color: white;
	transition: $defaultTransition;
	z-index: 999;
	&.show-enter-active,
	&.show-leave-active {
		opacity: 0;
		transform: translate(-50%, 100%);
		.icon {
			transform: rotateY(-720deg);
		}
	}
	.icon {
		position: relative;
		width: 2.1em;
		height: 2.1em;
		border-radius: 50%;
		background-color: white;
		background-repeat: no-repeat;
		margin-right: 0.6em;
		z-index: 9;
		transition: all 700ms 300ms ease-in-out;
		&:after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: 1em;
			height: 1em;
			transform: translate(-50%, -50%);
			background-repeat: no-repeat;
			background-position: 50%;
			background-size: contain;
			background-image: url("@/assets/svg/cash.svg");
		}
	}
	.title {
		font-weight: 400;
		font-size: 1em;
		letter-spacing: -0.02em;
	}
	.cost {
		letter-spacing: 0;
		padding-left: 0.5em;
		font-weight: 500;
		font-size: 1.063em;
	}
}
</style>
