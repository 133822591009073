<template>
	<div class="bottom_bar">
		<div class="buttons">
			<div class="settings icon"></div>
			<div class="stats icon"></div>
			<div class="telephone_book icon"></div>
			<div class="refresh icon"></div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.bottom_bar {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 1.5em 1.3em;
	border-top-left-radius: 9px;
	border-top-right-radius: 9px;
	background-color: $bgColor;
	z-index: 9999;
	&:after {
		content: "";
		position: absolute;
		top: 0.5em;
		left: 50%;
		transform: translateX(-50%);
		width: 2.188em;
		height: 4px;
		border-radius: 50px;
		background-color: #505050;
	}
}
.buttons {
	display: flex;
	justify-content: space-between;
}
.icon {
	width: 1.813em;
	height: 1.813em;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: contain;
	&.settings {
		background-image: url("~@/assets/svg/bottomBar/settings.svg");
	}
	&.stats {
		background-image: url("~@/assets/svg/bottomBar/stats.svg");
	}
	&.telephone_book {
		background-image: url("~@/assets/svg/bottomBar/telephone_book.svg");
	}
	&.refresh {
		background-image: url("~@/assets/svg/bottomBar/refresh.svg");
	}
}
</style>
